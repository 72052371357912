
import LogoMain from '../assets/img/svg/Logo.webp'
import Sign from '../assets/img/icons/sign.png'
// BGs
import IntroBg from '../assets/img/backs/bg-intro.png'
import Texture from '../assets/img/backs/texture.png'
import FooterBg from '../assets/img/backs/bg-footer.png'
import DetailsBg from '../assets/img/backs/bg-details.png'
import MaterialsBg from '../assets/img/backs/bg-materials.png'
import WhyBg from '../assets/img/backs/bg-why.png'
// About
import Ic01 from '../assets/img/icons/ic01.png'
import Ic02 from '../assets/img/icons/ic02.png'
import Ic03 from '../assets/img/icons/ic03.png'
// Features
import Team from '../assets/img/team.png'
// Info
import InfoPic1 from '../assets/img/mt01.png'
import InfoPic2 from '../assets/img/mt02.png'
import InfoPic3 from '../assets/img/mt03.png'
// Materials
import IconM from '../assets/img/icons/ic-check.png'
// Price
import Price1 from '../assets/img/price01.png'
import Price2 from '../assets/img/price02.png'
import Price3 from '../assets/img/price03.png'
// Why
import PhonePic from '../assets/img/phone.png'
import WhyIcon1 from '../assets/img/icons/l01.png'
import WhyIcon2 from '../assets/img/icons/l02.png'
import WhyIcon3 from '../assets/img/icons/l03.png'
// How
import HowPic1 from '../assets/img/how01.png'
import HowPic2 from '../assets/img/how02.png'
import HowPic3 from '../assets/img/how03.png'
import HowPic4 from '../assets/img/how04.png'
// Gallery
import p001 from '../assets/img/gallery/p001.jpg'
import p002 from '../assets/img/gallery/p002.jpg'
import p003 from '../assets/img/gallery/p003.jpg'
import p004 from '../assets/img/gallery/p004.jpg'
import p005 from '../assets/img/gallery/p005.jpg'
import p006 from '../assets/img/gallery/p006.jpg'
import p007 from '../assets/img/gallery/p007.jpg'
import p008 from '../assets/img/gallery/p008.jpg'
import p009 from '../assets/img/gallery/p009.jpg'
import p010 from '../assets/img/gallery/p010.jpg'
import p011 from '../assets/img/gallery/p011.jpg'
import p012 from '../assets/img/gallery/p012.jpg'
import p013 from '../assets/img/gallery/p013.jpg'
import p014 from '../assets/img/gallery/p014.jpg'
import p015 from '../assets/img/gallery/p015.jpg'
// Begin
import Pic1 from '../assets/img/begin01.png'
import Pic2 from '../assets/img/begin02.png'
import PhotoQuote from '../assets/img/master.png'


// import LogoMain from 'https://v5.airtableusercontent.com/v1/13/13/1670198400000/i0ooNaYg4Joro7cHBU-3yw/VN4Iz90CfHj6zdu7uJrnmR8fwrd6ZPv79BvlthZVzZbaGB7T5NjjIazgwLRP468AgNsg1AKhrMvjaf9BsrqiQtfjyheHb20r7n6mSSAclG4/NFFhRHQxqk3AOTNWaD6Sd-R40_UuNNjqI_Bw5juF_G4'


export const data = [
  {
    site: {
      title: 'Штукатурка',
      description: 'Шаблон',
      keywords: ['штукатурка', 'лендинг'],
      favicon: '',
      presentation: '',
      legal: 'Все права защищены',
      partner: 'Наш спонсор',
      logo: {
        main: LogoMain,
        footer: LogoMain,
        public: ['', ''],
        slogan: ['Машинная штукатурка стен', 'в Киеве и области']
      }
    },
    phone: {
      link: '+380447891234',
      text: '+38(044)789-12-34',
      call: 'Обратный звонок'
    },
    intro: {
      back: IntroBg,
      title: ['штукатурка', 'стен'],
      subtitle: 'Машинная',
      small: ['гипсовой смесью', 'с гарантией 2 года'],
      button: {
        link: '/',
        text: 'Заказать штукатурку стен'
      }
    },
    about: {
      back: Texture,
      title: [
        'Работаем по договору',
        'Оплата по факту',
        'Без скрытых доплат'
      ],
      text: [
        'Мы, гарантируем качество наших работ и надлежащее выполнение взятое на себя обязательство официальным договором.',
        'Мы благодарны нашим клиентам за доверие и взамен оказываем обратное доверие, предлагая оплатить работу исключительно по факту ее завершения.',
        'Наша стоимость уже включает покрытие всех затрат, связанных с проведением работ, включая материалы и транспортные расходы. Мы не потребуем у вас доплаты в процессе работы или после.'
      ],
      icons: [Ic01, Ic02, Ic03]
    },
    features: {
      back: Texture,
      title: 'Команда профессионалов',
      subtitle: 'В нашей команде работают коренные киевляне. Каждый - с большим опытом работы и настоящий профессионал своего дела.',
      info: {
        title: 'Работаем - эффективно',
        text1: 'В своей работе мы руководствуемся принципом - делай хорошо сразу. и этот подход принес свои результаты. Более 60% клиентов обращаются к нам по рекомендации. Мы любим своих клиентов и это взаимно.',
        text2: 'Для штукатурки стен мы применяем высокопроизводительные штукатурные станции и специализированные гипсовые смеси. Такой подход позволяет получить идеально ровные стены, пригодные для оклеивания обоями без необходимости шпаклевания.'
      },
      pic: Team,
      name: ['Виктор Сергеевич', 'Сергей Семенович'],
      bio: [
        'Профессиональный строитель со стажем работы более 10 лет. Владеет шпателем с ловкостью самурая.',
        'Профессиональный строитель со стажем работы более 35 лет. окончил МГСУ. Очень требовательный к качеству  выполнения работ.'
      ],
      button: {
        link: '',
        text: ''
      }
    },
    details: {
      back: DetailsBg,
      title: ['Производительность', 'Толщина штукатурки', 'Бригада'],
      small: ['человека', 'до', 'cм'],
      text: ['3-4', ['150м', '/день'], '2'],
      sup: ['', '2', '']
    },
    info: {
      title: [
        'Машинное нанесение штукатурки',
        'Специальные гипсовые смеси',
        'Идеально ровные стены'
      ],
      text: [
        'Нанесение штукатурки машинным способом значительно повышает продуктивность и качество выполнение работы. Штукатурная смесь готовиться автоматически в машине, благодаря чему она всегда однородная и правильной консистенции.',
        'Специализированная гипсовая смесь для машинной штукатурки наносится по немецкой технологии. Благодаря этому стена получается очень гладкой и идеально подходит сразу для склеивания обоями. После высыхания, стены получаются такими же белыми как после шпаклевки.',
        'Мы используем штукатурку стен по макетам, выставляем с лазерной точностью. Установка макетов производится с помощью лазерного 3D построителя плоскостей. Благодаря чему оштукатуривание стены получаются идеально ровными даже если изначально они были с перепадом.'
      ],
      pics: [InfoPic1, InfoPic2, InfoPic3]
    },
    materials: {
      back: MaterialsBg,
      title: 'Реально все включено',
      subtitle: 'Наша стоимость уже включает покрытие всех затрат, связанных с проведение работ, включая материалы и транспортные расходы. Мы не потребуем у вас доплат в процессе работы или после.',
      boxTitle: [
        'Доставка',
        'Материалы',
        'Работа',
        'Вынос мусора'
      ],
      boxText: [
        'Мы не берем доплат за доставку оборудования и материалов, в какой бы части Киева или области не находился ваш объект.',
        'Высококачественная гипсовая штукатурка уже включена в стоимость и благодаря нашим оптовым скидкам обходиться вам даже дешевле, чем если бы вы захотели купить ее самостоятельно.',
        'Работает ли нас несколько специалистов или полная бригада - стоимость остается неизменной.',
        'Мы соблюдаем чистоту и убираем за собой - бесплатно! каждый объект после нашей работы остается в том же состоянии. что и был до нас.'
      ],
      icon: IconM,
    },
    cta: {
      sub: 'Все это - всего:',
      big: '530 Грн/м',
      sup: '2',
      small: 'или по телефону:',
      button: {
        link: '/',
        text: 'Заказать штукатурку стен'
      }
    },
    price: {
      title: 'Мы предлагаем',
      subtitle: 'Наша стоимость уже включает покрытие всех затрат, связанных с проведение работ, включая материалы и транспортные расходы. Мы не потребуем у вас доплат в процессе работы или после.',
      pics: [Price1, Price2, Price3],
      boxTitle: [
        'Цементно-песчаная',
        'Гипсовая под обои',
        'Гипсовая под шпаклевку',
      ],
      boxText: [
        'Производиться для ванных, кухонь и других влажных помещений',
        'Производиться для ванных, кухонь и других влажных помещений',
        'Производиться для ванных, кухонь и других влажных помещений',
      ],
      boxPrice: [
        '530 Грн/м',
        '550 Грн/м',
        '590 Грн/м',
      ],
      boxSup: ['2', '2', '2'],
      boxSmall: ['все включено', 'все включено', 'все включено'],
      button1: {
        link: '/',
        text: 'Заказать'
      },
      button2: {
        link: '/',
        text: 'Заказать'
      },
      button3: {
        link: '/',
        text: 'Заказать'
      }
    },
    why: {
      back: WhyBg,
      title: 'Почему так дешево?',
      subtitle: 'Это самый популярный вопрос который мы слышим от клиентов.',
      text: [
        'Машинная штукатурка позволяет значительно сэкономить время на приготовление штукатурной смеси и ее набросе. Обычная производительность бригады из 4 человек при ручном способе штукатурки - 40м2/ день. Машинная штукатурка позволяет этой же бригаде делать объем в 150 м2 / день',
        'Машинная штукатурка позволяет значительно сэкономить время на приготовление штукатурной смеси и ее набросе. Обычная производительность бригады из 4 человек при ручном способе штукатурки - 40м2/ день. Машинная штукатурка позволяет этой же бригаде делать объем в 150 м2 / день',
        'Машинная штукатурка позволяет значительно сэкономить время на приготовление штукатурной смеси и ее набросе. Обычная производительность бригады из 4 человек при ручном способе штукатурки - 40м2/ день. Машинная штукатурка позволяет этой же бригаде делать объем в 150 м2 / день'
      ],
      phone: PhonePic,
      icons: [WhyIcon1, WhyIcon2, WhyIcon3]
    },
    how: {
      title: 'Как мы работаем',
      subtitle: 'Прежде, чем начать работу - мы проводим осмотр помещения, уточняем планы по дальнейшей эксплуатации, даем советы по дизайну интерьера.',
      boxTitle: [
        'Расчет точной стоимости',
        'Подписание договора',
        'Завоз оборудования и начало работ',
        'Приемка работы и оплата'
      ],
      boxText: [
        'Мы бесплатно выполним обмер вашего объема и рассчитаем стоимость работ на месте.',
        'Когда вы будете готовы, мы снова приедем к вам на объект для подписания договора и обсуждения даты начала работ.',
        'Точно в назначенное время бригада появится на вашем объекте и немедленно приступит к работе.',
        'Мы уверены в своем качестве и сами вручим вам. Правило для проверки ровности стен. Только после этого предложим оплатить нашу работу.'
      ],
      pics: [HowPic1, HowPic2, HowPic3, HowPic4]
    },
    gallery: {
      title: 'Наша работа',
      subtile: 'Мы стараемся делать хорошие фотографии, чтобы мы могли рассмотреть нашу работу. Но видно - штукатурим мы лучше, чем умеем делать фото.',
      pics: [p001, p002, p003, p004, p005, p006, p007, p008, p009, p010, p011, p012, p013, p014, p015],
      caption: {
        title: [
          ['Штукатурка стен в 2-х комнатной', 'квартире на Крещатике.'],
          ['Штукатурка стен в 3-х комнатной', 'квартире на Подоле.'],
          ['Штукатурка стен в 4-х комнатной', 'квартире на Площади Независимости.']
        ],
        name: ['Площадь по стенам:', 'Срок выполнения работ:'],
        meta: [['342 м', '3 дня'], ['453 м', '5 дней'], ['678 м', '7 дней']],
        sup: ['2', '2', '2'],
        result: ['Итог:', ['3400 грн', '4200 грн', '5700 грн']],
      }
    },
    begin: {
      back: Texture,
      title: 'С чего же начать...',
      subtitles: ['с монтажа электрики?', 'со штукатурки стен?'],
      quote: {
        text: 'Прокладка проводки по голым стенам, до штукатурки кажется вполне логичным решением. на практике же, штукатурка должна быть самым первмм этапом работ. Да, потом электрикам придется прорезать канавки для прокладки кабелей в новехонькой штукатурке. Но потом достаточно просто затереть гипсовой шпатлевкой канавки и получить идеальные стены с подровень вставленными коробками для розеток и выключателей. По голым же стенам невозможно выставить глубину укладки коробок в ровень с будущей штукатуркой. В таком случае нужно либо дважды привлекать электриков: сначала для прокладки кабелей, потом для монтажа коробок, либо мириться с тем, что каждая коробка будет на своей глубине. А довольно часто - и выпирать из штукатурки.',
        name: 'Сергей Сергеевич',
        bio: 'Профессиональный строитель со стажем работы более 35 лет. Окончил КИСИ. Очень требователен к качеству выполнения работ.'
      },
      pic1: Pic1,
      pic2: Pic2,
      photo: PhotoQuote
    },
    footer: {
      title: 'Есть другой вопрос?',
      subtitle: 'Обладая огромным опытом строительно-ремонтных работ, мы с удовольствием экспертно проконсультируем вас по любому вопросу.',
      form: {
        title: 'Задайте ваш вопрос через форму',
        textarea: 'Введите ваш вопрос',
        name: 'Ваше имя',
        phone: 'Номер телефона',
        button: {
          action: '/',
          text: 'ЗАДАТЬ ВОПРОС'
        }
      },
      phone: {
        title: 'Или по телефону:',
        link: '/',
        text: 'Вам перезвонить?'
      },
      back: FooterBg,
      sign: Sign,
    }
  }

]
