
import './App.css'
import Header from './components/Header'
import About from './components/About'
import Features from './components/Features'
import Details from './components/Details'
import Info from './components/Info'
import Materials from './components/Materials'
import Cta from './components/Cta'
import Price from './components/Price'
import Why from './components/Why'
import How from './components/How'
import Gallery from './components/Gallery'
import Begin from './components/Begin'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <Features />
      <Details />
      <Info />
      <Materials />
      <Cta />
      <Price />
      <Why />
      <How />
      <Gallery />
      <Begin />
      <Footer />
    </div>
  )
}

export default App
