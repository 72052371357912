import React from 'react'
import { data } from '../data/data'


const Info = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="info" key={index}>
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="center">
                    <img src={item.info.pics[0]} alt="material 01" />
                    <h3 className="box-title">{item.info.title[0]}</h3>
                    <p className="box-text" > {item.info.text[0]}</p>
                  </div >
                </div >
                <div className="col-4" >
                  <div className="center" >
                    <img src={item.info.pics[1]} alt="material 02" />
                    <h3 className="box-title" > {item.info.title[1]}</h3 >
                    <p className="box-text" > {item.info.text[1]}</p >
                  </div >
                </div >
                <div className="col-4" >
                  <div className="center" >
                    <img src={item.info.pics[2]} alt="material 03" />
                    <h3 className="box-title" > {item.info.title[2]}</h3 >
                    <p className="box-text" > {item.info.text[2]}</p >
                  </div >
                </div >
              </div >
            </div >
          </section >
        ))
      }
    </>
  )
}

export default Info
