import React from 'react'
import { data } from '../data/data'


const Features = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="features" key={index} style={{ backgroundImage: `url(${item.features.back})` }}>

            <div className="container">
              <h2 className="section-title">{item.features.title}</h2>
              <h3 className="section-subtitle">{item.features.subtitle}</h3>
              <div className="tlps" >
                <div className="tlp left" ><strong>{item.features.name[0]}</strong><br />{item.features.bio[0]}</div >
                <img src={item.features.pic} alt="team" />
                <div className="tlp right" ><strong>{item.features.name[1]}</strong><br />{item.features.bio[1]}</div >
              </div >
              <p className="section-subtitle" > {item.features.info.text1}</p >
              <h2 className="section-title" > {item.features.info.title}</h2 >
              <p className="section-subtitle" > {item.features.info.text2}</p >
            </div >
          </section >
        ))
      }
    </>
  )
}

export default Features
