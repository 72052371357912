import React from 'react'
import { data } from '../data/data'

const Gallery = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="gallery" key={index}>
            <div className="container">
              <h2 className="section-title">{item.gallery.title}</h2>
              <h3 className="section-subtitle" > {item.gallery.subtile}</h3>
            </div >
            <div className="gallery-item first-line" >
              <div className="container" >
                <div className="row" >
                  <div className="col-6" >
                    <div className="figure big" >
                      <div className="overlay" >
                        <div className="content" >
                          <h3 className="box-title white-text" > {item.gallery.caption.title[0][0]} < br /> {item.gallery.caption.title[0][1]}</h3 >
                          <p className="box-text white-text" > {item.gallery.caption.name[0]} {item.gallery.caption.meta[0][0]}<sup>{item.gallery.caption.sup[0]}</sup><br />{item.gallery.caption.name[1]} {item.gallery.caption.meta[0][1]}<br /><br />
                            {item.gallery.caption.result[0]} <strong>{item.gallery.caption.result[1][0]}</strong>
                          </p >
                        </div >
                      </div >
                      <img src={item.gallery.pics[0]} alt="pic" />
                    </div >
                  </div >
                  <div className="col-6" >
                    <div className="row" >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[1]} alt="pic" />
                        </div >
                      </div >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[2]} alt="pic" />
                        </div >
                      </div >
                    </div >
                    <div className="row" >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[3]} alt="pic" />
                        </div >
                      </div >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[4]} alt="pic" />
                        </div >
                      </div >
                    </div >
                  </div >
                </div >
              </div >
            </div >
            <div className="gallery-item" >
              <div className="container" >
                <div className="row" >
                  <div className="col-6" >
                    <div className="figure big" >
                      <div className="overlay" >
                        <div className="content" >
                          <h3 className="box-title white-text" > {item.gallery.caption.title[1][0]} < br /> {item.gallery.caption.title[1][1]}</h3 >
                          <p className="box-text white-text" > {item.gallery.caption.name[0]} {item.gallery.caption.meta[1][0]}<sup>{item.gallery.caption.sup[1]}</sup><br />{item.gallery.caption.name[1]} {item.gallery.caption.meta[1][1]}<br /><br />
                            {item.gallery.caption.result[0]} <strong>{item.gallery.caption.result[1][1]}</strong>
                          </p >
                        </div >
                      </div >
                      <img src={item.gallery.pics[5]} alt="pic" />
                    </div >
                  </div >
                  <div className="col-6" >
                    <div className="row" >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[6]} alt="pic" />
                        </div >
                      </div >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[7]} alt="pic" />
                        </div >
                      </div >
                    </div >
                    <div className="row" >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[8]} alt="pic" />
                        </div >
                      </div >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[9]} alt="pic" />
                        </div >
                      </div >
                    </div >
                  </div >
                </div >
              </div >
            </div >
            <div className="gallery-item" >
              <div className="container" >
                <div className="row" >
                  <div className="col-6" >
                    <div className="figure big" >
                      <div className="overlay" >
                        <div className="content" >
                          <h3 className="box-title white-text" > {item.gallery.caption.title[2][0]} < br /> {item.gallery.caption.title[2][1]}</h3 >
                          <p className="box-text white-text" > {item.gallery.caption.name[0]} {item.gallery.caption.meta[2][0]}<sup>{item.gallery.caption.sup[2]}</sup><br />{item.gallery.caption.name[1]} {item.gallery.caption.meta[2][1]}<br /><br />
                            {item.gallery.caption.result[0]} <strong>{item.gallery.caption.result[1][2]}</strong>
                          </p >
                        </div >
                      </div >
                      <img src={item.gallery.pics[10]} alt="pic" />
                    </div >
                  </div >
                  <div className="col-6" >
                    <div className="row" >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[11]} alt="pic" />
                        </div >
                      </div >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[12]} alt="pic" />
                        </div >
                      </div >
                    </div >
                    <div className="row" >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[13]} alt="pic" />
                        </div >
                      </div >
                      <div className="col-6" >
                        <div className="figure" >
                          <div className="overlay" >
                            <div className="content" ></div >
                          </div >
                          <img src={item.gallery.pics[14]} alt="pic" />
                        </div >
                      </div >
                    </div >
                  </div >
                </div >
              </div >
            </div >
          </section >
        ))
      }
    </>
  )
}

export default Gallery
