import React from 'react'
import { data } from '../data/data'


const Cta = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="cta" key={index}>
            <div className="container center">
              <h3 className="cta-sub">{item.cta.sub}</h3>
              <h2 className="cta-big" > {item.cta.big} < sup > {item.cta.sup}</sup></h2 >
              <a href={item.cta.button.link} className="btn big my-4" > {item.cta.button.text}</a >
              <p className="cta-small" > {item.cta.small}</p >
              <h3><a href={`tel:${item.phone.link}`} className="cta-sub last">{item.phone.text}</a></h3 >
            </div >
          </section >
        ))
      }
    </>
  )
}

export default Cta
