import React from 'react'
import { data } from '../data/data'


const Why = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="why" key={index} style={{ backgroundImage: `url(${item.why.back})` }}>
            <div className="container">
              <div className="row">
                <div className="col-3">
                  <img className="phone-pic" src={item.why.phone} alt="phone" />
                </div>
                <div className="col-8 ml-5">
                  <h2 className="why-title">{item.why.title}</h2>
                  <br />
                  <br />
                  <h3 className="why-subtitle" > {item.why.subtitle}</h3 >
                  <div className="flex v-center" >
                    <img src={item.why.icons[0]} alt="icon" />
                    <p className="box-text text-left" > {item.why.text[0]}</p >
                  </div >
                  <div className="flex v-center" >
                    <img src={item.why.icons[1]} alt="icon" />
                    <p className="box-text text-left" > {item.why.text[1]}</p >
                  </div >
                  <div className="flex v-center" >
                    <img src={item.why.icons[2]} alt="icon" />
                    <p className="box-text text-left" > {item.why.text[2]}</p >
                  </div >
                </div >
                <div className="col-1" ></div >
              </div >
            </div >
          </section >
        ))
      }
    </>
  )
}

export default Why
