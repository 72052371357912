import React from 'react'
import { data } from '../data/data'

const Begin = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="begin" key={index} style={{ backgroundImage: `url(${item.begin.back})` }}>
            <div className="container center">
              <h2 className="section-title">{item.begin.title}</h2>
              <div className="row">
                <div className="col-6">
                  <h3 className="box-title my-5">{item.begin.subtitles[0]}</h3>
                  <img src={item.begin.pic1} alt="begin" />
                </div >
                <div className="col-6" >
                  <h3 className="box-title my-5" > {item.begin.subtitles[1]}</h3 >
                  <img src={item.begin.pic2} alt="begin" />
                </div >
              </div >
              <div className="row mt-5" >
                <div className="col-1" ></div >
                <div className="col-10" >
                  <div className="white r-10 border p-4 pb-1 m-quote" >
                    <p className="box-text text-left" > {item.begin.quote.text}</p >
                  </div >
                  <div className="col-1" ></div >
                </div >
                <div className="row mt-5 ml-4" >
                  <div className="col-3" ></div >
                  <div className="col-2" >
                    <img src={item.begin.photo} alt="master" />
                  </div >
                  <div className="col-4 my-2 master" >
                    <p className="box-title text-left" > {item.begin.quote.name}</p >
                    <p className="box-subtitle text-left" > {item.begin.quote.bio}</p >
                  </div >
                  <div className="col-3" ></div >
                </div >
              </div >
            </div >
          </section >
        ))
      }
    </>

  )
}

export default Begin
