import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

var Airtable = require('airtable');

const API_KEY = process.env.REACT_APP_API_KEY
const BASE_ID = process.env.REACT_APP_BASE_ID


var base = new Airtable({ apiKey: API_KEY }).base(BASE_ID);

base('Settings').find('recKrP4fJsCmnavbd', function (err, record) {
  if (err) { console.error(err); return; }
  console.log('Retrieved', record.id);
});



// new Airtable({ apiKey: API_KEY }).base(BASE_ID);
// axios.defaults.baseURL = `https://api.airtable.com/v0/${BASE_ID}/map/`;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers['Authorization'] = `Bearer ${API_KEY}`;

// export default function useData() {
//   const [data, setData] = useState(null)

//   const getData = async () => {
//     return axios.get('/').then(
//       res => setData(res.data.records)
//     )
//   }
//   return {
//     getData,
//     data
//   }
// }


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
