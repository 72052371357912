import React from 'react'
import { data } from '../data/data'

const Why = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="price" key={index}>
            <h2 className="section-title">{item.price.title}</h2>
            <h3 className="section-subtitle">{item.price.subtitle}</h3>
            <div className="container" >
              <div className="row" >
                <div className="col-4" >
                  <div className="box white center shadow content price" >
                    <img src={item.price.pics[0]} alt="pic" />
                    <h3 className="box-subtitle" > {item.price.boxTitle[0]}</h3 >
                    <p className="box-text" > {item.price.boxText[0]}</p >
                    <h3 className="box-title" > {item.price.boxPrice[0]} < sup > {item.price.boxSup[0]}</sup ></h3 >
                    <p className="box-text" > {item.price.boxSmall[0]}</p >
                    <a href={item.price.button1.link} className="btn small" > {item.price.button1.text}</a >
                  </div >
                </div >
                <div className="col-4" >
                  <div className="box white center shadow content price active" >
                    <img src={item.price.pics[1]} alt="pic" />
                    <h3 className="box-subtitle blue" > {item.price.boxTitle[1]}</h3 >
                    <p className="box-text" > {item.price.boxText[1]}</p >
                    <h3 className="box-title" > {item.price.boxPrice[1]} < sup > {item.price.boxSup[1]}</sup ></h3 >
                    <p className="box-text" > {item.price.boxSmall[1]}</p >
                    <a href={item.price.button2.link} className="btn small" > {item.price.button2.text}</a >
                  </div >
                </div >
                <div className="col-4" >
                  <div className="box white center shadow content price" >
                    <img src={item.price.pics[2]} alt="pic" />
                    <h3 className="box-subtitle blue" > {item.price.boxTitle[2]}</h3 >
                    <p className="box-text" > {item.price.boxText[2]}</p >
                    <h3 className="box-title" > {item.price.boxPrice[2]} < sup > {item.price.boxSup[2]}</sup ></h3 >
                    <p className="box-text" > {item.price.boxSmall[2]}</p >
                    <a href={item.price.button3.link} className="btn small" > {item.price.button3.text}</a >
                  </div >
                </div >
              </div >
            </div >
          </section >
        ))
      }
    </>
  )
}

export default Why
