import React from 'react'
import { data } from '../data/data'


const Footer = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <footer key={index} style={{ backgroundImage: `url(${item.footer.back})` }}>
            <div className="container">
              <h2 className="section-title white-text">{item.footer.title}</h2>
              <h3 className="section-subtitle white-text">{item.footer.subtitle}</h3>
              <div className="row" >
                <div className="col-1" ></div >
                <div className="col-6" >
                  <p className="box-subtitle white-text text-left ml-1" > {item.footer.form.title}</p >
                  <div className="form" >
                    <form method='post' action={item.footer.form.button.action}>
                      <div className="form-group row ml-1">
                        <textarea className="form-control" id="FormControlTextarea" rows="3"
                          placeholder={item.footer.form.textarea}></textarea>
                      </div >
                      <div className="form-group row ml-1 w-50" >
                        <input type="text" className="col-6 form-control" id="InputName" placeholder={item.footer.form.name} />
                      </div >
                      <div className="form-group row ml-1 w-50" >
                        <input type="text" className="col-6 form-control" id="InputPhone" placeholder={item.footer.form.phone} />
                      </div >
                      <button type="submit" className="btn small ml-1" > {item.footer.form.button.text}</button >
                    </form >
                  </div >
                </div >
                <div className="col-4 mt-5 ml-4" >
                  <p className="box-text white-text text-left mt-2 mb-1" > {item.footer.phone.title}</p >
                  <h2 className="text-left" > <a href={`tel:${item.phone.link}`} className="box-title white-text" > {item.phone.text}</a ></h2 >
                  <a href={item.footer.phone.link} className="recall text-left" > {item.footer.phone.text}</a >
                </div >
                <div className="col-1" ></div >
              </div >
            </div >
            <div className="footer-bottom" >
              <div className="container" >
                <div className="row" >
                  <div className="col-4" >
                    <a href="/" className="logo-footer" >
                      <img src={item.site.logo.main} alt="Logo" />
                      <p className="box-text white-text text-left" > {item.site.logo.slogan[0]} < br /> {item.site.logo.slogan[1]}</p >
                    </a >
                  </div >
                  <div className="col-4" >
                    <p className="box-text white-text pt-3" >@{new Date().getFullYear()} - {item.site.title}. {item.site.legal}</p >
                  </div >
                  <div className="col-4 text-right right" >
                    <a href="/">
                      <img src={item.footer.sign} alt="sign" />
                      <span className="box-text white-text pl-2">{item.site.partner}</span>
                    </a >
                  </div >
                </div >
              </div >
            </div >
          </footer >
        ))
      }
    </>
  )
}

export default Footer
