import React from 'react'
import { data } from '../data/data'

const Details = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="details" key={index} style={{ backgroundImage: `url(${item.details.back})` }}>
            <div className="container">
              <div className="row">
                <div className="col-3">
                  <div className="box figure">
                    <div className="content">
                      <h3 className="small-title">{item.details.title[0]}</h3>
                      <p className="text"><span className="span big">{item.details.text[0]}</span><small>{item.details.small[0]}</small></p >
                    </div >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="box figure" >
                    <div className="content" >
                      <h3 className="small-title" > {item.details.title[1]}</h3 >
                      <p className="text" ><small>{item.details.small[1]}</small><span className="span big" > {item.details.text[1][0]} < sup > {item.details.sup[1]}</sup > {item.details.text[1][1]}</span ></p >
                    </div >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="box figure" >
                    <div className="content" >
                      <h3 className="small-title" > {item.details.title[2]}</h3 >
                      <p className="text" > <span className="span big" > {item.details.text[2]}</span > <small>{item.details.small[2]}</small></p >
                    </div >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="box figure" >
                    <div className="content" >
                      <h3 className="small-title" > Просыхание</h3 >
                      <p className="text" > <span className="span big" > 7</span > <small>дней</small></p >
                    </div >
                  </div >
                </div >
              </div >
            </div >
          </section >
        ))
      }
    </>
  )
}

export default Details
