import React from 'react'
import { data } from '../data/data'


const How = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="how" key={index}>
            <div className="container">
              <h2 className="section-title">{item.how.title}</h2>
              <h3 className="section-subtitle">{item.how.subtitle}</h3>
              <div className="row" >
                <div className="col-3" >
                  <div className="how center" >
                    <img src={item.how.pics[0]} alt="pic" />
                    <h3 className="box-title" > {item.how.boxTitle[0]}</h3 >
                    <p className="box-text" > {item.how.boxText[0]}</p >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="how center" >
                    <img src={item.how.pics[1]} alt="pic" />
                    <h3 className="box-title" > {item.how.boxTitle[1]}</h3 >
                    <p className="box-text" > {item.how.boxText[1]}</p >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="how center" >
                    <img src={item.how.pics[2]} alt="pic" />
                    <h3 className="box-title" > {item.how.boxTitle[2]}</h3 >
                    <p className="box-text" > {item.how.boxText[2]}</p >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="how center" >
                    <img src={item.how.pics[3]} alt="pic" />
                    <h3 className="box-title" > {item.how.boxTitle[3]}</h3 >
                    <p className="box-text" > {item.how.boxText[3]}</p >
                  </div >
                </div >
              </div >

            </div >
          </section >
        ))
      }
    </>
  )
}

export default How
