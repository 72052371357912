import React from 'react'
import { data } from '../data/data'


const About = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="about" key={index} style={{ backgroundImage: `url(${item.about.back})` }}>
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="box one v-start">
                    <div className="content white center">
                      <img src={item.about.icons[0]} alt="icon" />
                      <h3 className="box-title">{item.about.title[0]}</h3>
                      <p className="box-text">{item.about.text[0]}</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="box one v-center">
                    <div className="content white center">
                      <img src={item.about.icons[1]} alt="icon" />
                      <h3 className="box-title">{item.about.title[1]}</h3>
                      <p className="box-text">{item.about.text[1]}</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="box one v-end">
                    <div className="content white center">
                      <img src={item.about.icons[2]} alt="icon" />
                      <h3 className="box-title">{item.about.title[2]}</h3>
                      <p className="box-text">{item.about.text[2]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))
      }
    </>

  )
}

export default About
