import React from 'react'
import { data } from '../data/data'

const Materials = () => {
  return (
    <>
      {
        data.map((item, index) => (
          <section id="materials" key={index} style={{ backgroundImage: `url(${item.materials.back})` }}>
            <div className="container">
              <h2 className="section-title">{item.materials.title}</h2>
              <h3 className="section-subtitle">{item.materials.subtitle}</h3>
              <div className="row" >
                <div className="col-3" >
                  <div className="box two" >
                    <img src={item.materials.icon} alt="icon" />
                    <div className="content white border-top-blue" >
                      <h3 className="box-title" > {item.materials.boxTitle[0]}</h3 >
                      <p className="box-text" > {item.materials.boxText[0]}</p >
                    </div >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="box two" >
                    <img src={item.materials.icon} alt="icon" />
                    <div className="content white border-top-blue" >
                      <h3 className="box-title" > {item.materials.boxTitle[1]}</h3 >
                      <p className="box-text" > {item.materials.boxText[1]}</p >
                    </div >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="box two" >
                    <img src={item.materials.icon} alt="icon" />
                    <div className="content white border-top-blue" >
                      <h3 className="box-title" > {item.materials.boxTitle[2]}</h3 >
                      <p className="box-text" > {item.materials.boxText[2]}</p >
                    </div >
                  </div >
                </div >
                <div className="col-3" >
                  <div className="box two" >
                    <img src={item.materials.icon} alt="icon" />
                    <div className="content white border-top-blue" >
                      <h3 className="box-title" > {item.materials.boxTitle[3]}</h3 >
                      <p className="box-text" > {item.materials.boxText[3]}</p >
                    </div >
                  </div >
                </div >
              </div >
            </div >
          </section >
        ))
      }
    </>
  )
}

export default Materials
