
import React from 'react'
import { data } from '../data/data'

const Header = () => {

  return (
    <>
      {
        data.map((item, index) => (
          <header id="intro" key={index} style={{ backgroundImage: `url(${item.intro.back})` }}>
            <div className="container">
              <div className="row">
                <div className="col-7 my-4">
                  <div id="logo">
                    <img src={item.site.logo.main} alt="Logo" />
                    <p>{item.site.logo.slogan[0]}<br />{item.site.logo.slogan[1]}</p>
                  </div>
                </div>
                <div className="col-5 text-right my-4">
                  <div className="d-flex right-menu">
                    <a href={`tel:${item.phone.link}`} className="phone">{item.phone.text}</a>
                    <a href="/" className="btn small" > {item.phone.call}</a >
                  </div >
                </div >
              </div >
            </div >
            <div className="container" >
              <div className="row" >
                <div className="col-6" >
                  <p className="intro-subtitle" > {item.intro.subtitle}</p >
                  <p className="intro-title" > {item.intro.title[0]}</p >
                  <div className="d-flex" >
                    <p className="intro-title" > {item.intro.title[1]}</p >
                    <p className="intro-small" > {item.intro.small[0]} < br /> {item.intro.small[1]}</p >
                  </div >
                  <a href={item.intro.button.link} className="btn big" > {item.intro.button.text}</a >
                  <p><a href={`tel:${item.phone.link}`} className="phone-intro">{item.phone.text}</a></p >
                </div >
              </div >
            </div >
          </header >
        ))
      }
    </>
  )
}

export default Header
